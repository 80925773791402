import { Contacts } from "./components/Contacts";
import { DEFAULT_THEME } from '@legex/ui-kit';
import styled from "styled-components";
import { CONTENT_HORIZONTAL_PADDING, CONTENT_WIDTH_DEFAULT, CONTENT_WIDTH_MIN, MOBILE_MAX_WIDTH } from "../../constants";
import { FooterLinks } from "./components/FooterLinks";
import { FC } from "react";

const Wrapper = styled.div<{transparent: boolean}>`
    padding: 2rem ${CONTENT_HORIZONTAL_PADDING}rem;
    background-color: ${( {transparent} ) => transparent ? "transparent" : DEFAULT_THEME.color.primary[100]};
    color: ${DEFAULT_THEME.color.fontColor.inverseColor};
    fill: ${DEFAULT_THEME.color.fontColor.inverseColor};
    grid-template-columns: auto minmax(${CONTENT_WIDTH_MIN - CONTENT_HORIZONTAL_PADDING * 2}rem, ${CONTENT_WIDTH_DEFAULT}rem) auto;
    display: grid;
`;

const FooterContent = styled.div`
    max-width: ${CONTENT_WIDTH_DEFAULT}rem;
    display: flex;
    
    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        flex-direction: column;
    }
`;

export const Footer: FC<{transparent: boolean}> = ( {transparent} ) => {
    return (
        <Wrapper transparent={transparent}>
            <div />
            <FooterContent>
                <FooterLinks />
                <Contacts />
            </FooterContent>
            <div />
        </Wrapper>
    )
}

