import styled from "styled-components";
import { components, IndicatorProps } from "react-select";
import { DEFAULT_THEME } from "@legex/ui-kit";
import { FC } from "react";

export const IndicatorStyled = styled(components.DropdownIndicator)`
    padding: 0 !important;
    color: ${DEFAULT_THEME.color.neutral[40]} !important;
`;

export const Indicator: FC<IndicatorProps<any, any>> = ({
    children,
    ...props
}) =>
    <IndicatorStyled {...props}>{children}</IndicatorStyled>

