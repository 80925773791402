import styled from "styled-components";
import { components } from "react-select";

export const MenuLeft = styled(components.Menu)`
    width: initial !important;
    min-width: 100%;
`;

export const MenuRight = styled(components.Menu)`
    width: initial !important;
    min-width: 100%;
    right: 0;
`;
