import styled from "styled-components";
import { DEFAULT_THEME } from "@legex/ui-kit";
import { FC } from "react";
import { ControlProps } from "react-select";
import { IndicatorStyled } from "./Indicator";

const ControlStyled = styled.div`
    display: flex;
    cursor: pointer;
    flex: 1;
    
    &:hover {
        ${IndicatorStyled} {
            color: ${DEFAULT_THEME.color.neutral[20]} !important;
        }
    }
`;
export const Control: FC<ControlProps<any, any>> = ({
    children,
    ...props
}) =>
    <ControlStyled {...props.innerProps}>{children}</ControlStyled>;
