import styled from "styled-components";
import { FC } from "react";
import { MenuListProps } from "react-select/src/components/Menu";

const MenuListStyled = styled.div``;

export const MenuList: FC<MenuListProps> = ({
    children
}) =>
    <MenuListStyled>{children}</MenuListStyled>
