import {H1, H2, H3, Link, Text1} from "@legex/ui-kit";
import styled from "styled-components";
import {CONTENT_WIDTH_DEFAULT} from "../../../constants";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
    max-width: ${CONTENT_WIDTH_DEFAULT}rem;
    display: grid;
    justify-self: center;
    grid-gap: 1rem;
    padding: 3rem 1rem;
    align-content: start;

    ul {
        margin: 0;
    }

    ${Text1} {
        text-align: justify;
    }
    
    img {
        max-width: 100%;
    }
    
    table {
        border-spacing: 0;
        border-collapse: collapse;
        td, th {
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 0.5rem 1rem;
        }
    }
`;

const BlockHeader = styled(H2)`
    font-size: 1.25rem;
    margin-top: 1.5rem;
    line-height: 1.5rem;
`;

const BlockSubHeader = styled(H3)`
    font-size: 1rem;
    margin-top: 1.5rem;
    line-height: 1.5rem;
`;

const H1WithMargin = styled(H1)`
    margin-bottom: 1.5rem;
    font-size: 2rem;
`;

export const NftMarketplaceFunctionalityPage = () => {

    const {t} = useTranslation(["nftMarketplaceSelling"])

    return (
        <Wrapper>
            <H1WithMargin>
                {t("nftMarketplaceFunc:title")}
            </H1WithMargin>

            <BlockHeader>{t("nftMarketplaceFunc:home.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:home.blocks.title")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:home.blocks.topSell")}</li>
                <li>{t("nftMarketplaceFunc:home.blocks.topCollections")}</li>
                <li>{t("nftMarketplaceFunc:home.blocks.hot")}</li>
                <li>{t("nftMarketplaceFunc:home.blocks.recommended")}</li>
            </ul>
            <Text1>{t("nftMarketplaceFunc:home.discovery")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:marketplace.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:marketplace.desc")}</Text1>
            <Text1>{t("nftMarketplaceFunc:marketplace.topFilters.title")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:marketplace.topFilters.nft")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.topFilters.collections")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.topFilters.auctions")}</li>
            </ul>
            <Text1>{t("nftMarketplaceFunc:marketplace.middle")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:marketplace.nftCategories")}</li>
            </ul>
            <Text1>{t("nftMarketplaceFunc:marketplace.ordering.title")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:marketplace.ordering.new")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.ordering.popular")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.ordering.cheap")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.ordering.expensive")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.ordering.creation")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.ordering.ends")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.ordering.starts")}</li>
            </ul>
            <Text1>{t("nftMarketplaceFunc:marketplace.sellingType.title")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:marketplace.sellingType.auction")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.sellingType.fixPrice")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.sellingType.notSetup")}</li>
            </ul>
            <Text1>{t("nftMarketplaceFunc:marketplace.extraFilters.title")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:marketplace.extraFilters.singleMultipleCopy")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.extraFilters.verifiedUserOnly")}</li>
                <li>{t("nftMarketplaceFunc:marketplace.extraFilters.onlyMineSubscriptions")}</li>
            </ul>
            <Text1>{t("nftMarketplaceFunc:marketplace.filteringTogether")}</Text1>

            <BlockHeader>{t("nftMarketplaceFunc:userMenu.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:userMenu.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:blockChainAuth.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:blockChainAuth.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:verification.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:verification.steps.title")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:verification.steps.username")}</li>
                <li>{t("nftMarketplaceFunc:verification.steps.role")}</li>
                <li>{t("nftMarketplaceFunc:verification.steps.email")}</li>
                <li>{t("nftMarketplaceFunc:verification.steps.bio")}</li>
                <li>{t("nftMarketplaceFunc:verification.steps.social")}</li>
                <li>{t("nftMarketplaceFunc:verification.steps.existed")}</li>
            </ul>

            <BlockHeader>{t("nftMarketplaceFunc:userProfile.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:userProfile.objects.title")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:userProfile.objects.avatar")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.name")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.wallet")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.email")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.bio")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.friends")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.subscribers")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.subscriptions")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.likes")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.edit")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.verification")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.objects.editProfile")}</li>
            </ul>
            <Text1>{t("nftMarketplaceFunc:userProfile.background")}</Text1>
            <Text1>{t("nftMarketplaceFunc:userProfile.nft.title")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:userProfile.nft.liked")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.nft.bided")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.nft.created")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.nft.bought")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.nft.onSell")}</li>
                <li>{t("nftMarketplaceFunc:userProfile.nft.onAuction")}</li>
            </ul>
            <BlockHeader>{t("nftMarketplaceFunc:userProfileEdit.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:userProfileEdit.attributes")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:userProfileEdit.avatar")}</li>
                <li>{t("nftMarketplaceFunc:userProfileEdit.name")}</li>
                <li>{t("nftMarketplaceFunc:userProfileEdit.email")}</li>
                <li>{t("nftMarketplaceFunc:userProfileEdit.bio")}</li>
                <li>{t("nftMarketplaceFunc:userProfileEdit.social")}</li>
                <li>{t("nftMarketplaceFunc:userProfileEdit.link")}</li>
                <li>{t("nftMarketplaceFunc:userProfileEdit.portfolio")}</li>
            </ul>
            <BlockHeader>{t("nftMarketplaceFunc:nftCreation.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:nftCreation.desc")}</Text1>
            <BlockSubHeader>{t("nftMarketplaceFunc:nftCreation.auction.title")}</BlockSubHeader>
            <Text1>{t("nftMarketplaceFunc:nftCreation.auction.desc")}</Text1>
            <BlockSubHeader>{t("nftMarketplaceFunc:nftCreation.fixPrice.title")}</BlockSubHeader>
            <Text1>{t("nftMarketplaceFunc:nftCreation.fixPrice.desc")}</Text1>
            <BlockSubHeader>{t("nftMarketplaceFunc:nftCreation.notForSell.title")}</BlockSubHeader>
            <Text1>{t("nftMarketplaceFunc:nftCreation.notForSell.desc")}</Text1>
            <BlockSubHeader>{t("nftMarketplaceFunc:nftCreation.selling.title")}</BlockSubHeader>
            <Text1>{t("nftMarketplaceFunc:nftCreation.selling.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:nftCardView.title")}</BlockHeader>
            <BlockSubHeader>{t("nftMarketplaceFunc:nftCardView.details.title")}</BlockSubHeader>
            <Text1>{t("nftMarketplaceFunc:nftCardView.details.desc")}</Text1>
            <BlockSubHeader>{t("nftMarketplaceFunc:nftCardView.owners.title")}</BlockSubHeader>
            <Text1>{t("nftMarketplaceFunc:nftCardView.owners.desc")}</Text1>
            <BlockSubHeader>{t("nftMarketplaceFunc:nftCardView.bidsView.title")}</BlockSubHeader>
            <Text1>{t("nftMarketplaceFunc:nftCardView.bidsView.desc")}</Text1>
            <BlockSubHeader>{t("nftMarketplaceFunc:nftCardView.historyView.title")}</BlockSubHeader>
            <Text1>{t("nftMarketplaceFunc:nftCardView.historyView.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:buying.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:buying.user")}</Text1>
            <Text1>{t("nftMarketplaceFunc:buying.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:bidding.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:bidding.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:auctionClosing.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:auctionClosing.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:shareNft.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:shareNft.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:claimNft.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:claimNft.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:burnNft.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:burnNft.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:transferNft.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:transferNft.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:outOfTrade.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:outOfTrade.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:notifications.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:notifications.variants")}
                <br/>{t("nftMarketplaceFunc:notifications.filters")}
                <br/>{t("nftMarketplaceFunc:notifications.systemFilters")}
                <br/>{t("nftMarketplaceFunc:notifications.categories.title")}
            </Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:notifications.categories.subscriptions")}</li>
                <li>{t("nftMarketplaceFunc:notifications.categories.likes")}</li>
                <li>{t("nftMarketplaceFunc:notifications.categories.creation")}</li>
                <li>{t("nftMarketplaceFunc:notifications.categories.selling")}</li>
                <li>{t("nftMarketplaceFunc:notifications.categories.auction")}</li>
                <li>{t("nftMarketplaceFunc:notifications.categories.bid")}</li>
                <li>{t("nftMarketplaceFunc:notifications.categories.other")}</li>
            </ul>
            <BlockHeader>{t("nftMarketplaceFunc:collectionCreation.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:collectionCreation.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:collectionCardView.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:collectionCardView.desc")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:collectionCardView.name")}</li>
                <li>{t("nftMarketplaceFunc:collectionCardView.info")}</li>
                <li>{t("nftMarketplaceFunc:collectionCardView.chainAddress")}</li>
                <li>{t("nftMarketplaceFunc:collectionCardView.avatar")}</li>
            </ul>
            <Text1>{t("nftMarketplaceFunc:collectionCardView.cover")}</Text1>
            <Text1>{t("nftMarketplaceFunc:collectionCardView.preview")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:collectionCardView.previewOfSell")}</li>
                <li>{t("nftMarketplaceFunc:collectionCardView.all")}</li>
            </ul>
            <BlockHeader>{t("nftMarketplaceFunc:currencyChange.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:currencyChange.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:subscription.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:subscription.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:social.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:social.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:faq.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:faq.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:search.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:search.desc")}</Text1>
            <ul>
                <li>{t("nftMarketplaceFunc:search.users")}</li>
                <li>{t("nftMarketplaceFunc:search.collections")}</li>
                <li>{t("nftMarketplaceFunc:search.nft")}</li>
            </ul>
            <BlockHeader>{t("nftMarketplaceFunc:referral.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:referral.desc")}</Text1>
            <BlockHeader>{t("nftMarketplaceFunc:administration.title")}</BlockHeader>
            <Text1>{t("nftMarketplaceFunc:administration.desc")}</Text1>

        </Wrapper>
    )
}

export default NftMarketplaceFunctionalityPage
