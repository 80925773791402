import {Bars, ChevronDown, ChevronLeft, Times} from "@legex/icons";
import styled from "styled-components";
import { DEFAULT_THEME, Spinner } from "@legex/ui-kit";
import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import { withSuspense } from "../../hocs/withSuspense";
import { Link } from 'react-router-dom';
import {ChildrenMenuLink, LinksList} from "../linksList";
import { LanguageSelector } from "./LanguageSelector";

const IconWrapper = styled.div`
    svg {
        height: 2rem;
        fill: ${DEFAULT_THEME.color.neutral[0]};
    }
`

const BarsIcon: FC<{onClick: () => void}> = ({onClick}) => (
    <IconWrapper onClick={() => onClick()}>
        <Bars weight="light" />
    </IconWrapper>
);

const PANEL_WIDTH = 20;

const Panel = styled.div<{opened: boolean}>`
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    color: ${DEFAULT_THEME.color.fontColor.color};
    background: ${DEFAULT_THEME.color.neutral[0]};
    box-shadow: ${DEFAULT_THEME.shadow[10]};
    z-index: 1005;
    position: fixed;
    width: ${PANEL_WIDTH}rem;
    top: 0;
    transition: left 0.35s ease;
    left: ${({ opened }) => opened ? '0' : `-${PANEL_WIDTH + 1}rem`};
    display: grid;
    grid-template-rows: min-content min-content min-content auto min-content;
`;

const Hider = styled.div<{opened: boolean}>`
    display: ${({ opened }) => opened ? 'block' : 'none'};
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.2);
`;

const SEPARATOR_COLOR = DEFAULT_THEME.color.neutral[20];

const LinkWrapper = styled.div`
    border-top: 1px solid ${SEPARATOR_COLOR};
    display: grid;
    &:last-child {
        border-bottom: 1px solid ${SEPARATOR_COLOR};
    }
    
`;

const LinkRouter = styled(Link)`
    text-decoration: none;
    color: currentColor;
    padding: 1rem 2.5rem;
    cursor: pointer;
`;

const LinkA = styled.a`
    text-decoration: none;
    color: currentColor;
    padding: 1rem 2.5rem;
    cursor: pointer;
`;

const DropdownLinkComponent = styled.div`

`;

const DropdownTitle = styled(LinkA)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ChevronWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    width: 1.25rem;
    svg {
        height: 1rem;
        fill: ${DEFAULT_THEME.color.neutral[50]}
    }
`;

const DropdownLinkDropdown = styled.div<{opened: boolean}>`
    transition: max-height 0.25s ease;
    max-height: ${({opened}) => opened ? '100rem' : 0};
    overflow: hidden;
    margin-left: 2.5rem;
    display: grid;
    
    ${LinkA}, ${LinkRouter} {
        padding: 1rem 2.5rem 1rem 1.5rem;
    }
    
    ${LinkWrapper}:last-child {
        border-bottom: 0;
    }
`;

const DropdownLink: FC<{label: string, menuChildren: ChildrenMenuLink[]}> = ({label, menuChildren}) => {
    const {t} = useTranslation(['index']) as any;

    const [opened, setOpened] = useState(false)

    const Chevron = opened ? ChevronDown : ChevronLeft;

    return (
        <DropdownLinkComponent tabIndex={0} onClick={() => setOpened(!opened)}>
            <DropdownTitle>
                { t(label)}
                <ChevronWrapper>
                    <Chevron />
                </ChevronWrapper>
            </DropdownTitle>
            <DropdownLinkDropdown opened={opened}>
                {menuChildren.map((it) => (
                    <LinkWrapper>
                        {it.link ?
                            <LinkRouter to={it.link} key={it.label}>{t(it.label)}</LinkRouter>
                            :
                            <LinkA href={it.href} key={it.label}>{t(it.label)}</LinkA>
                        }
                    </LinkWrapper>
                ))}
            </DropdownLinkDropdown>
        </DropdownLinkComponent>
    )
}

const Links: FC<{onClick: () => void}> = ({onClick}) => {
    const {t} = useTranslation(['index']) as any;

    return (
        <>
            {
                LinksList.map((it) => (
                    <LinkWrapper key={ it.label } >
                        {
                            it.link ?
                                <LinkRouter to={ it.link } key={ it.label } onClick={onClick}>{ t(it.label) }</LinkRouter>
                                : it.href ?
                                <LinkA href={ it.href } key={ it.label }>{ t(it.label) }</LinkA>
                                : <DropdownLink label={it.label} menuChildren={it.children || []}/>
                        }
                    </LinkWrapper>
                ))
            }
        </>
    )
}

const LinksLazy = withSuspense<{onClick: () => void}>(Links, <Spinner />)

const Title = styled.div`
    justify-self: center;
    margin-left: -1.5rem;
`;

const DeclarationStyled = styled.div`
    justify-self: center;
    padding: 1.5rem;
    color: ${DEFAULT_THEME.color.neutral[50]};
`;

const Declaration = () => {
    const [t] = useTranslation(['index']);
    return (
        <DeclarationStyled>{t('index:Tagline')}</DeclarationStyled>
    )
}

const DeclarationLazy = withSuspense(Declaration, <Spinner />)

const LinksWrapper = styled.div`
    display: inline-grid;
    grid-template-rows: repeat(100, min-content);
    overflow: scroll;
    margin-bottom: -1px;
`;

const Header = styled.div`
    display: inline-grid;
    grid-template-columns: min-content auto;
    padding: 1.5rem;
    font-size: 1.5rem;
    
    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
`;

const Separator = styled.div `
    width: 80%;
    justify-self: center;
    border-top: 1px solid ${SEPARATOR_COLOR};
`;

const LanguageSelectorWrapper = styled.div`
    
    border-top: 1px solid ${SEPARATOR_COLOR};
    padding: 1rem 1rem;
    
`;

export const MobileMenu = () => {

    const [opened, setOpened] = useState<boolean>(false);

    return (
        <>
            <BarsIcon onClick={() => setOpened(true)} />
            <Hider opened={opened}  onClick={() => setOpened(false)}/>
            <Panel opened={opened}>
                <Header>
                    <Times height={15} onClick={() => setOpened(false)} />
                    <Title>LEGEX</Title>
                </Header>
                <Separator />
                <DeclarationLazy />

                <LinksWrapper>
                    <LinksLazy onClick={() => setOpened(false)}/>
                </LinksWrapper>

                <LanguageSelectorWrapper>
                    <LanguageSelector langName="full"/>
                </LanguageSelectorWrapper>
            </Panel>
        </>
    )
}
