import { Spinner } from "@legex/ui-kit";
import { apiWebClient } from "../../utils/axios";
import React from "react";
import { userService } from "../../utils/user";
import styled from "styled-components";
import { withSuspense } from "../../components/hocs/withSuspense";
import { WithTranslation, withTranslation } from "react-i18next";

const Wrapper = styled.div`
    display: grid;
    justify-content: center;
    align-content: center;
    
    >* {
        align-self: center;
        justify-self: center;
    }
`;

const Disclaimer = styled.div`
    font-weight: 200;
    font-size: 2rem;
    margin-bottom: 1rem;
`;

class AuthorizeComponent extends React.Component<WithTranslation> {

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        const authCode = params.get('code')

        if (!authCode) {
            window.location.pathname = "/"
        }

        const redirectUri = params.get('redirect-uri') || process.env.REACT_APP_INDEX_ROOT

        const tokenParams = new URLSearchParams()
        tokenParams.append('code', authCode || '')
        tokenParams.append('client_id', 'legex.io')
        tokenParams.append('client_secret', '10{n22t(DJT#H-E{TfqXg$Ru')
        tokenParams.append('grant_type', 'authorization_code')

        apiWebClient.post(`/auth/v1/oauth/token` || '', tokenParams, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(result => {
            userService.setupAuthTokens(result.data.access_token, result.data.refresh_token);

            window.location.href = redirectUri || ''

        }, error => {
            console.log("Can't get authorization tokens", error)
        })
    }

    render() {
        return (
            <Wrapper>
                <Disclaimer>{this.props.t("index:Sign.Authorizing")}...</Disclaimer>
                <Spinner />
            </Wrapper>
        );
    }
}

export const Authorize =
    withSuspense(
        withTranslation(['index'])(
            AuthorizeComponent
        )
    )

