import {Telegram, Twitter, LinkedIn, Facebook, Medium} from '@legex/icons';
import styled from "styled-components";
import { DEFAULT_THEME } from "@legex/ui-kit";
import { LINK_COLOR, LINK_COLOR_HOVER } from "../constants";

const IconLink = styled.a`
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 1.1rem;
    border: 1px solid ${ LINK_COLOR };
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${LINK_COLOR};
    fill: ${DEFAULT_THEME.color.neutral[10] };
    
    svg {
        width: 1rem;
        height: 1rem;
    }

    &:hover {
      border-color: ${LINK_COLOR_HOVER}
    }
`;

const SocialWrapper = styled.div`
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: auto auto auto auto auto;
`;

export const Social = () => {
    return (
        <>
            <SocialWrapper>
                <IconLink href="https://t.me/joinchat/6XRidIrILDNlYzYy" target="_blank" rel="noopener noreferrer" aria-label="telegram">
                    <Telegram/>
                </IconLink>
                <IconLink href="https://twitter.com/legexcorp" target="_blank" rel="noopener noreferrer">
                    <Twitter/>
                </IconLink>
                <IconLink href="https://www.linkedin.com/company/legex-io/about/" target="_blank" rel="noopener noreferrer">
                    <LinkedIn/>
                </IconLink>
                <IconLink href="https://fb.me/legexcorp" target="_blank" rel="noopener noreferrer">
                    <Facebook/>
                </IconLink>
                <IconLink href="https://medium.com/@legex" target="_blank" rel="noopener noreferrer">
                    <Medium/>
                </IconLink>
            </SocialWrapper>
        </>
    )
}
