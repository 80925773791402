import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link as RouterLink } from 'react-router-dom';
import { DEFAULT_THEME, Spinner } from "@legex/ui-kit";
import { withSuspense } from "../../hocs/withSuspense";
import { LINK_COLOR, LINK_COLOR_ACTIVE, LINK_COLOR_HOVER } from "../constants";
import { MOBILE_MAX_WIDTH } from "../../../constants";
import { ChevronDown, ChevronUp } from "@legex/icons";

const MOBILE_BORDER_COLOR = DEFAULT_THEME.color.neutral[70];

const LinksBlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    flex: 1;
    margin-right: 1rem;

    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        padding: 1rem;
        margin-right: 0;
        &:not(:last-child) {
            border-bottom: 1px solid ${MOBILE_BORDER_COLOR};
        }
    }
`;

const LinksBlockHeader = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${DEFAULT_THEME.color.neutral[30]};
    fill: ${DEFAULT_THEME.color.neutral[30]};
    font-weight: 600;
    margin-bottom: 0.5rem;

    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        margin-left: -1rem;
        padding-left: 1rem;
        margin-right: -1rem;
        padding-right: 1rem;
        padding-bottom: 0;
        margin-bottom: 0;
    }
`;

const LinksContent = styled.div<{opened: boolean}>`
    display: flex;
    flex-direction: column;
    justify-items: start;
    align-items: start;
    overflow: hidden;
    transition: max-height 0.2s ease, margin-top 0.2s ease;
    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        max-height: ${({opened}) => !opened ? '0' : '10rem'};
    }

    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        margin-top: ${ ({opened}) => !opened ? '0' : '0.5rem' };
    }
`;

const ChevronWrapper = styled.div`
    @media(min-width: ${MOBILE_MAX_WIDTH}) {
        display: none;
    }
`;

const LinksBlock: FC<{ header: string, opened: boolean, toggleOpen: (opened: boolean) => void }> = ({
    header,
    opened,
    toggleOpen,
    children,
    ...props
}) => {

    const Chevron = opened ? ChevronUp : ChevronDown;


    return (
        <LinksBlockWrapper>
            <LinksBlockHeader onClick={() => toggleOpen(!opened)}>
                {header}
                <ChevronWrapper>
                    <Chevron height={16} />
                </ChevronWrapper>
            </LinksBlockHeader>
            <LinksContent opened={opened}>
                {children}
            </LinksContent>
        </LinksBlockWrapper>
    )
}

const Link = styled(RouterLink)`
    color: ${LINK_COLOR};
    margin-top: 0.5rem;
    text-decoration: none;
    
    &:hover {
        color: ${LINK_COLOR_HOVER};
    }

    &:active {
        color: ${LINK_COLOR_ACTIVE};
    }
`;

const LinksWrapper = styled.div`
    flex: 1;
    display: flex;
    margin-right: 1rem;
    

    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 2rem;
        border: 1px solid ${MOBILE_BORDER_COLOR};
        border-radius: 1rem;
    }
`;

const LinksComponent = () => {

    const [opened, setOpened] = useState<string | null>(null)
    const {t} = useTranslation(['index']);

    return (
        <LinksWrapper>
            <LinksBlock header="Legex" opened={opened === 'legex'} toggleOpen={(opened) => { setOpened(opened ? 'legex' : null)}}>
                <Link to="/about-us">{ t('index:Links.About us') }</Link>
                <Link to="/news">{ t('index:Links.News') }</Link>
                <Link to="/contacts">{ t('index:Links.Contacts') }</Link>
            </LinksBlock>
            <LinksBlock header={ t('index:Links.Policies') } opened={opened === 'policies'} toggleOpen={(opened) => { setOpened(opened ? 'policies' : null)}}>
                <Link to="/legal">{ t('index:Links.Legal') }</Link>
                <Link to="/privacy">{ t('index:Links.Privacy') }</Link>
            </LinksBlock>
            <LinksBlock header={ t('index:Links.Customer') } opened={opened === 'customer'} toggleOpen={(opened) => { setOpened(opened ? 'customer' : null)}}>
                <Link to="/pricing">{ t('index:Links.Pricing') }</Link>
                <Link to="/guides">{ t('index:Links.Guides') }</Link>
                <Link to="/support">{ t('index:Links.Support') }</Link>
            </LinksBlock>
            <LinksBlock header={ t('index:Links.For Developers') } opened={opened === 'developers'} toggleOpen={(opened) => { setOpened(opened ? 'developers' : null)}}>
                <Link to="/integrations">{ t('index:Links.Integrations') }</Link>
                <Link to="/api">{ t('index:Links.API') }</Link>
                <Link to="/examples">{ t('index:Links.Examples') }</Link>
            </LinksBlock>
        </LinksWrapper>
    )
}

export const FooterLinks = withSuspense(LinksComponent, <Spinner />)
