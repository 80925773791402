import styled from "styled-components";
import { DEFAULT_THEME } from "@legex/ui-kit";
import { HEADER_HEIGHT_DEFAULT } from "../../../constants";
import { ReactComponent as LegexLogo } from '../../../../assets/legex_logo_white.svg';
import { Link } from 'react-router-dom';

const TitleStyle = styled.div`
    display: flex;
    height: ${HEADER_HEIGHT_DEFAULT}rem;
    align-items: center;
    letter-spacing: .2rem;
    
`;

const LogoWrapper = styled.div`

    border: 2px solid ${DEFAULT_THEME.color.neutral[30]};
    border-radius: 2.5rem;
    padding: 0.5rem;
    line-height: 0;

    svg {
        height: 1.75rem;
        line-height: 2.5rem;
    }
`;

/*const LegexTitle = styled(H1)`
    font-weight: 700;
`;*/

export const Title = () => {
    return (
        <TitleStyle>
            <Link to="/">
                <LogoWrapper>
                    <LegexLogo />
                </LogoWrapper>
            </Link>
            {/*<LegexTitle>LEGEX</LegexTitle>*/}
        </TitleStyle>
    )
}
