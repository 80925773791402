import { Button, Spinner } from "@legex/ui-kit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { withSuspense } from "../../hocs/withSuspense";
import React, { useState } from "react";
import { fingerPrintJs } from "../../../utils/axios";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1rem;
    padding: 0.5rem 0;
    min-width: 10rem;
`

const SignButtonsComponent = () => {
    const {t} = useTranslation(['index']);
    const [visitorId, setVisitorId] = useState<string | undefined>(undefined);

    (async () => {
        const fingerPrint = await (await fingerPrintJs).get()
        setVisitorId(fingerPrint.visitorId)
    })()

    return (
        <Wrapper>
            {/* eslint-disable-next-line no-restricted-globals */}
            <Button color={ 'secondary' } onClick={() => (location as any).href=
                `${process.env.REACT_APP_SSO_API_URL}/v1/oauth/authorize?redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_INDEX_ROOT || ""}/authorize`)}&client_id=${encodeURIComponent("legex.io")}&response_type=code&scope=openid&finger_print=${visitorId}`}
            >{ t('index:Sign.Sign In') }</Button>
            {/* eslint-disable-next-line no-restricted-globals */}
            <Button color={ 'primary' } onClick={() => (location as any).href=process.env.REACT_APP_SSO_SIGN_UP_URL}>{ t('index:Sign.Sign Up') }</Button>
        </Wrapper>
    )
}

export const SignButtons = withSuspense(SignButtonsComponent, <Spinner />)
