import { EventEmitter } from "events";
import { apiWebClient, axiosConfig } from "./axios";

export interface User {
    id: string;
    name: string;
    middleName: string;
    lastName: string;
    phone: string;
    email: string;
    roles: UserRole
}

export enum UserRole {
    User = 'User',
    TechSupport = 'TechSupport',
    TechSupportLead = 'TechSupportLead',
    ComplianceManager = 'ComplianceManager',
    ComplianceOfficer = 'ComplianceOfficer',
    SystemAdmin = 'SystemAdmin',
    SuperAdmin = 'SuperAdmin'
}


class UserService {

    userEvents = new EventEmitter();
    user: User | null | undefined = undefined;
    accessToken: string | undefined = undefined;
    refreshToken: string | undefined = undefined;

    constructor() {
        this.userEvents.addListener("user/update", (usr) => {
            this.user = usr
        })
    }

    setupAuthTokens(access_token?: string, refresh_token?: string) {
        this.accessToken = access_token;
        this.refreshToken = refresh_token

        if (access_token) {
            sessionStorage.setItem("legex-access", access_token)
        } else {
            sessionStorage.removeItem("legex-access")
        }
        if (refresh_token) {
            sessionStorage.setItem("legex-refresh", refresh_token)
        } else {
            sessionStorage.removeItem("legex-access")
        }

        axiosConfig.bearerToken = access_token;

        if (access_token) {
            apiWebClient.get("/auth/v1/id").then((data) => {
                console.log("user ", data.data)
                userService.userEvents.emit("user/update", data.data)
            }, error => {
                userService.userEvents.emit("user/update", null)
                console.log('error getting id', error)
            })

        } else {
            userService.userEvents.emit("user/update", null)
        }
    }

    exit() {
        apiWebClient.delete("/auth/v1/auth").then((data) => {
            this.setupAuthTokens(undefined, undefined)
        }, error => {
            this.setupAuthTokens(undefined, undefined)
            console.log('error getting id', error)
        })
    }
}

export const userService = new UserService()

const savedAcToken = sessionStorage.getItem("legex-access")
const savedRfToken = sessionStorage.getItem("legex-refresh")
setTimeout(() => {
    userService.setupAuthTokens(savedAcToken || undefined, savedRfToken || undefined);
})
