import styled from "styled-components";
import { components } from 'react-select';

export const SingleValue = styled(components.SingleValue)`
    color: currentColor !important;
    word-break: keep-all;
    user-select: none;
    margin-left: 0 !important;
    margin-right: 0 !important;
    transform: none !important;
    top: 0 !important;
    position: initial !important;
    overflow: initial !important;
    max-width: initial !important;
`;
