import React, { lazy, Suspense } from "react";
import { Spinner } from "@legex/ui-kit";
import styled from "styled-components";
import NftMarketplaceFunctionalityPage from "../pages/Articles/NftMarketplaceFunctionalityPage";

const IndexPage = lazy(() => import('../pages/IndexPage'));
const NewsPage = lazy(() => import('../pages/NewsListPage'));
const AboutUsPage = lazy(() => import('../pages/AboutUsPage'));
const LegalPage = lazy(() => import('../pages/LegalPage'));
const PrivacyPage = lazy(() => import('../pages/PrivacyPage'));
const UnderConstructionPage = lazy(() => import('../pages/UnderConstructionPage'));
const NewsSinglePage = lazy(() => import('../pages/News/NewsSinglePage'));
const ContactsPage = lazy(() => import('../pages/ContactsPage'));
const EstonianCryptoCompanyRegistrationPage = lazy(() => import('../pages/Services/EstonianCryptoCompanyRegistrationPage'));
const EstonianCompanyRegistrationPage = lazy(() => import('../pages/Services/EstonianCompanyRegistrationPage'));
const CompanyRegistrationPage = lazy(() => import('../pages/Services/CompanyRegistrationPage'));
const BankAccountOpeningPage = lazy(() => import('../pages/Services/BankAccountOpeningPage'));
const InvoicingAndSettlementsPage = lazy(() => import('../pages/Services/InvoicingAndSettlementsPage'));
const CryptoCompanyRegistrationSwissPage = lazy(() => import('../pages/Services/CryptoCompanyRegistration/Swiss'));
const CryptoCompanyRegistrationLiechtensteinPage = lazy(() => import('../pages/Services/CryptoCompanyRegistration/Liechtenstein'));
const OtcPage = lazy(() => import('../pages/Services/OtcPage'));
const SumsubPage = lazy(() => import('../pages/Articles/SumsubPage'));
const NftMarketplaceSellingPage = lazy(() => import('../pages/Articles/NftMarketplaceSellingPage'));

const BoxedSpinnerWrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-content: center;
`;

const BoxedSpinner = () => {
    return (
        <BoxedSpinnerWrapper>
            <Spinner />
        </BoxedSpinnerWrapper>
    )
}

export const IndexPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <IndexPage />
    </Suspense>
)

export const NewsPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <NewsPage />
    </Suspense>
)

export const AboutUsPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <AboutUsPage />
    </Suspense>
)

export const LegalPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <LegalPage />
    </Suspense>
)

export const PrivacyPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <PrivacyPage />
    </Suspense>
)

export const UnderConstructionPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <UnderConstructionPage />
    </Suspense>
)

export const NewsSinglePageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <NewsSinglePage />
    </Suspense>
)

export const ContactsPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <ContactsPage />
    </Suspense>
)


export const EstonianCryptoCompanyRegistrationPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <EstonianCryptoCompanyRegistrationPage />
    </Suspense>
)

export const EstonianCompanyRegistrationPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <EstonianCompanyRegistrationPage />
    </Suspense>
)

export const CompanyRegistrationPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <CompanyRegistrationPage />
    </Suspense>
)

export const BankAccountOpeningPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <BankAccountOpeningPage />
    </Suspense>
)

export const InvoicingAndSettlementsPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <InvoicingAndSettlementsPage />
    </Suspense>
)

export const CryptoCompanyRegistrationSwissPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <CryptoCompanyRegistrationSwissPage />
    </Suspense>
)

export const CryptoCompanyRegistrationLiechtensteinPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <CryptoCompanyRegistrationLiechtensteinPage />
    </Suspense>
)

export const OtcPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <OtcPage />
    </Suspense>
)

export const SumsubPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <SumsubPage />
    </Suspense>
)

export const NftMarketplaceSellingPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <NftMarketplaceSellingPage />
    </Suspense>
)

export const NftMarketplaceFunctionalityPageLazy = () => (
    <Suspense fallback={<BoxedSpinner />}>
        <NftMarketplaceFunctionalityPage />
    </Suspense>
)
