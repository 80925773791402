import React, { FC } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Footer, Header } from "./components";
import styled, { css } from "styled-components";
import { isMobile } from "./utils";
import {
    AboutUsPageLazy,
    BankAccountOpeningPageLazy,
    CompanyRegistrationPageLazy,
    ContactsPageLazy,
    CryptoCompanyRegistrationLiechtensteinPageLazy,
    CryptoCompanyRegistrationSwissPageLazy,
    EstonianCompanyRegistrationPageLazy,
    EstonianCryptoCompanyRegistrationPageLazy,
    IndexPageLazy,
    InvoicingAndSettlementsPageLazy,
    LegalPageLazy,
    NewsPageLazy,
    NewsSinglePageLazy, NftMarketplaceFunctionalityPageLazy,
    NftMarketplaceSellingPageLazy,
    OtcPageLazy,
    PrivacyPageLazy,
    SumsubPageLazy,
    UnderConstructionPageLazy
} from "./routing/Routes";
import {CookieNotification} from "./components/CookieNotification";
import { Authorize } from "./pages/Utils/Authorize";

const PageWrapper = styled.div`
    min-height: 100vh;
    min-width: 320px;
    display: grid;
    grid-template-rows: min-content auto min-content;

    html, body {
        box-sizing: border-box;
        ${ isMobile ?
            css`
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            ` : ''
        }
    }
`;

const WrappedRoute: FC<{ path: string, component: any }> = ({path, component}) => {
    return (
        <>
            <Route path={ path } render={ (props) => (
                <>
                    <Header transparent={ false }/>
                    { component }
                    <Footer transparent={ false }/>
                </>
            )
            }/>
        </>
    )
};

export const App = () => {

    return (
        <div className="App">
            <BrowserRouter>
                <PageWrapper>
                    <Switch>
                        <Route path='/' component={ IndexPageLazy } exact/>
                        <WrappedRoute path='/news/:newsId' component={ <NewsSinglePageLazy /> }/>
                        <WrappedRoute path='/news' component={ <NewsPageLazy/> }/>
                        <WrappedRoute path='/about-us' component={ <AboutUsPageLazy/> }/>
                        <WrappedRoute path='/contacts' component={ <ContactsPageLazy/> }/>
                        <WrappedRoute path='/legal' component={ <LegalPageLazy/> }/>
                        <WrappedRoute path='/privacy' component={ <PrivacyPageLazy/> }/>
                        <WrappedRoute path='/services/estonian-crypto-company-registration' component={ <EstonianCryptoCompanyRegistrationPageLazy/> }/>
                        <WrappedRoute path='/services/estonian-company-registration' component={ <EstonianCompanyRegistrationPageLazy/> }/>
                        <WrappedRoute path='/services/company-registration' component={ <CompanyRegistrationPageLazy/> }/>
                        <WrappedRoute path='/services/bank-account-opening' component={ <BankAccountOpeningPageLazy/> }/>
                        <WrappedRoute path='/services/invoicing-and-settlements' component={ <InvoicingAndSettlementsPageLazy/> }/>
                        <WrappedRoute path='/services/crypto-company-registration/swiss' component={ <CryptoCompanyRegistrationSwissPageLazy/> }/>
                        <WrappedRoute path='/services/crypto-company-registration/estonia' component={ <EstonianCryptoCompanyRegistrationPageLazy/> }/>
                        <WrappedRoute path='/services/crypto-company-registration/liechtenstein' component={ <CryptoCompanyRegistrationLiechtensteinPageLazy/> }/>
                        <WrappedRoute path='/services/otc' component={ <OtcPageLazy /> }/>
                        <WrappedRoute path='/articles/sumsub' component={ <SumsubPageLazy /> }/>
                        <WrappedRoute path='/articles/nft-marketplace-selling' component={ <NftMarketplaceSellingPageLazy /> }/>
                        <WrappedRoute path='/articles/nft-marketplace-functionality' component={ <NftMarketplaceFunctionalityPageLazy /> }/>
                        <WrappedRoute path='/authorize' component={ <Authorize /> }/>
                        <WrappedRoute path='**' component={ <UnderConstructionPageLazy/> }/>
                    </Switch>
                </PageWrapper>
                <CookieNotification />
            </BrowserRouter>
        </div>
    );
}
