import { User, userService } from "../../../utils/user";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { DEFAULT_THEME } from "@legex/ui-kit";
import { ChevronDown, UserCircle } from "@legex/icons";
import { useClickOutside } from "../../../hoooks/useClickOutside";
import { useTranslation } from "react-i18next";

const color = DEFAULT_THEME.color.neutral[30]
const colorHover = DEFAULT_THEME.color.neutral[20]

const UserInfoBlock = styled.div<{opened: boolean}>`
    color: ${ ({opened}) => opened ? colorHover : color };
    margin-right: 1rem;
    cursor: pointer;
    position: relative;
    display: grid;
    grid-template-areas: 'name icon menu-toggle'
                         'email icon menu-toggle';

    svg {
        fill: ${ ({opened}) => opened ? colorHover : color };
    }

    &:hover {
        color: ${ colorHover };

        svg {
            fill: ${ colorHover };
        }

    }
`;

const Icon = styled.div`
    grid-area: icon;
    align-self: center;
    padding-left: 1rem;

    svg {
        height: 2.5rem;
    }
`;

const Name = styled.div`
    grid-area: name;
    justify-self: end;
`
const Email = styled.div`
    grid-area: email;
`
const MenuToggle = styled.div`
    grid-area: menu-toggle;
    align-self: center;
    margin-left: 0.5rem;
    padding-top: 0.25rem;

    svg {
        height: .8rem;
    }
`

const Menu = styled.div<{opened: boolean}>`
    display: ${({opened}) => opened ? 'block' : 'none'};
    position: absolute;
    background: ${DEFAULT_THEME.color.neutral[5]};
    box-shadow: ${DEFAULT_THEME.shadow[10]};
    padding: 0.5rem 0;
    right: 0;
    top: calc(100% + 0.5rem);
    border-radius: 0.5rem;
    flex-direction: column;
`;

const MenuItem = styled.div`
    white-space: nowrap;
    padding: 0.5rem 1.5rem;
    margin-right: 0;
    font-size: 1rem;
    color: ${DEFAULT_THEME.color.fontColor.color};

    &:hover, &:focus {
        background: ${DEFAULT_THEME.color.primary[20]};
    }
`;

export const UserInfo: FC<{ user: User }> = ({user}) => {

    const {t} = useTranslation(["index"])

    const [opened, setOpened] = useState<boolean>(false)

    const ref = React.useRef(null);
    useClickOutside(ref, () => {
        setOpened(false);
    });

    return (
        <UserInfoBlock ref={ ref } opened={opened} onClick={() => setOpened(!opened)}>
            <Name>
                { user.name } { user.lastName }
            </Name>
            <Email>
                { user.email }
            </Email>
            <Icon>
                <UserCircle weight="light"/>
            </Icon>
            {/*<MenuToggle>
                <ChevronDown weight="solid"/>
            </MenuToggle>*/ }

            <Menu opened={opened}>
                <MenuItem onClick={() => userService.exit()}>{t("index:Sign.Exit")}</MenuItem>
            </Menu>
        </UserInfoBlock>
    )
}
