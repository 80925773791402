import axios from "axios";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { userService } from "./user";

class AxiosConfig {
    bearerToken: string | undefined = undefined;
}

export const axiosConfig = new AxiosConfig();

export const fingerPrintJs = FingerprintJS.load();

export const apiWebClient = axios.create({
    baseURL: process.env.REACT_APP_INDEX_ROOT + "/api",
});

apiWebClient.interceptors.request.use((config) => {
    return (async () => {
        const fingerPrint = await (await fingerPrintJs).get()

        config.headers["X-Device-Fingerprint"] = fingerPrint.visitorId
        if (axiosConfig.bearerToken) config.headers['Authorization'] = `Bearer ${axiosConfig.bearerToken}`

        return config
    })()
});


export const authWebClient = axios.create({
    baseURL: process.env.REACT_APP_SSO_API_URL,
});

authWebClient.interceptors.request.use((config) => {
    return (async () => {
        const fingerPrint = await (await fingerPrintJs).get()

        config.headers["X-Device-Fingerprint"] = fingerPrint.visitorId

        return config
    })()
});
