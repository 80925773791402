import styled from "styled-components";
import {Button, DEFAULT_THEME, Link} from "@legex/ui-kit";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {withSuspense} from "../hocs/withSuspense";

const Wrapper = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 1rem 2rem 4rem;
    border-radius: 1rem;
    min-width: 5rem;
    max-width: 70%;
    padding: 1rem 2rem;
    background: ${DEFAULT_THEME.color.primary[80]};
    box-shadow: ${DEFAULT_THEME.shadow[10]};
    display: grid;
    grid-gap: 1rem;
    grid-auto-flow: column;
    justify-items: center;
    align-items: center;
    color: ${DEFAULT_THEME.color.primary[10]};
    z-index: 1000;
`

const COOKIE_NAME = "catAccCookie="

class CookieNotificationComponent extends React.Component<WithTranslation, { accept: string | undefined }> {

    state = {
        accept: document.cookie.split(";").map((it) => it.trim()).find((it) => it.startsWith(COOKIE_NAME))
    }

    setupCookie() {
        document.cookie = COOKIE_NAME + "1;max-age=259200";

        this.setState({accept: "1"})
    }

    render() {

        return this.state.accept ? null :
            <Wrapper>
                <span>{this.props.t('utils:Cookies.By continuing')} <Link to={"/privacy#cookies"} inverse>{this.props.t('utils:Cookies.Policy')}</Link>.</span>
                <Button onClick={() => this.setupCookie()}>OK</Button>
            </Wrapper>;
    }
}

export const CookieNotification =
    withSuspense(
        withTranslation(['utils'])(
            CookieNotificationComponent
        )
    )
