import i18n from "i18next"
import '../../../../../i18n';
import { Languages } from "../../../../../i18n";
import { Component } from "react";
import Select from "react-select";
import { Control, Indicator, MenuLeft, MenuRight, Option, SingleValue, ValueContainer } from "./react-select-components";

const fullNames = Object.keys(Languages).map((it) => (
    {value: it, label: Languages[it].full}
));

const shortNames = Object.keys(Languages).map((it) => (
    {value: it, label: Languages[it].short}
));


interface ILanguageSelectorState {
    language: string
}

interface ILanguageSelectorProps {
    langName?: 'full' | 'short',
    menuHorizontalPosition?: 'left' | 'right';
}

export class LanguageSelector extends Component<ILanguageSelectorProps, ILanguageSelectorState> {

    state = {
        language: i18n.language || 'en'
    }

    change = (lng: string) => this.changeLanguage(lng);

    componentDidMount() {
        i18n.on('languageChanged', this.change)
    }

    componentWillUnmount() {
        i18n.off('languageChanged', this.change);
    }

    changeLanguage(lng: string) {
        this.setState({language: lng});
    }

    render() {
        return (
            <Select
                value={ (this.props.langName === 'full' ? fullNames : shortNames).find(it => it.value === this.state.language) }
                options={ fullNames }
                isSearchable={ false }
                openMenuOnClick={ true }
                menuPlacement="top"
                components={ {
                    Control: Control,
                    IndicatorSeparator: null,
                    DropdownIndicator: Indicator,
                    Option: Option,
                    SingleValue: SingleValue,
                    ValueContainer: ValueContainer,
                    Menu: this.props.menuHorizontalPosition === 'left' ? MenuLeft : MenuRight,
                } }
                onChange={ (it) => {
                    i18n.changeLanguage(it?.value || 'en')
                } }
            />
        )
    }
}

