export type ChildrenMenuLink = {
    label: string,
    link?: string,
    href?: string,
};

export type MenuLink = {
    label: string,
    link?: string,
    href?: string,
    children?: ChildrenMenuLink[]
};

export const LinksList: MenuLink[] = [
    { label: 'index:Links.News', link: '/news' },
    { label: 'index:Links.Services.Services', children: [
        { label: 'index:Links.Services.Companies Registration', link: '/services/company-registration' },
        { label: 'index:Links.Services.Bank account opening', link: '/services/bank-account-opening' },
        { label: 'index:Links.Services.Invoicing and settlements', link: '/services/invoicing-and-settlements' },
        //{ label: 'index:Links.Services.OTC', link: '/services/otc' },
    ] },
    { label: 'index:Links.Integrations', link: '/integrations' },
    { label: 'index:Links.API', link: '/api' },
    { label: 'index:Links.Pricing', link: '/pricing' },
    { label: 'index:Links.Support', link: '/support' },
];
