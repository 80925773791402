import React, {FC} from "react";
import { useTranslation } from "react-i18next";
import { withSuspense } from "../../hocs/withSuspense";
import { DEFAULT_THEME, Spinner } from "@legex/ui-kit";
import { Link as RouterLink } from 'react-router-dom';
import styled from "styled-components";
import {ChildrenMenuLink, LinksList} from "../linksList";

const LinkRouter = styled(RouterLink)`
    color: ${DEFAULT_THEME.color.neutral[20]};
    text-decoration: none;
    margin-right: 1rem;
    outline: none;
    font-weight: 300;
    font-size: 1.05rem;
    
    &:hover {
        color: ${DEFAULT_THEME.color.neutral[10]};
        border-color: ${DEFAULT_THEME.color.neutral[10]};
    }

    &:active {
        color: ${DEFAULT_THEME.color.neutral[0]};
        border-color: ${DEFAULT_THEME.color.neutral[0]};
    }
    
    &:focus {
        border-bottom: 1px solid ${DEFAULT_THEME.color.neutral[20]};
    }
`;

const LinkA = styled.a`
    color: ${DEFAULT_THEME.color.neutral[20]};
    text-decoration: none;
    margin-right: 1rem;
    outline: none;
    font-weight: 300;
    font-size: 1.05rem;
    
    &:hover {
        color: ${DEFAULT_THEME.color.neutral[10]};
        border-color: ${DEFAULT_THEME.color.neutral[10]};
    }

    &:active {
        color: ${DEFAULT_THEME.color.neutral[0]};
        border-color: ${DEFAULT_THEME.color.neutral[0]};
    }
    
    &:focus {
        border-bottom: 1px solid ${DEFAULT_THEME.color.neutral[20]};
    }
`;


const DropdownLinkDropdown = styled.div`
    position: absolute;
    background: ${DEFAULT_THEME.color.neutral[5]};
    box-shadow: ${DEFAULT_THEME.shadow[10]};
    padding: 0.5rem 0;
    left: 0;
    top: calc(100%);
    border-radius: 0.5rem;
    display: none;
    flex-direction: column;
    
    ${LinkRouter}, ${LinkA} {
        white-space: nowrap;
        padding: 0.5rem 1.5rem;
        margin-right: 0;
        font-size: 1rem;
        color: ${DEFAULT_THEME.color.fontColor.color};
        
        &:hover, &:focus {
            background: ${DEFAULT_THEME.color.primary[20]};
        }
    }
`;

const DropdownLinkComponent = styled(LinkA)`
    position: relative;
    display: inline;
    
    &:hover, &:focus {
        ${DropdownLinkDropdown} {
            display: flex;
        }
    }
`;


const DropdownLink: FC<{label: string, menuChildren: ChildrenMenuLink[]}> = ({label, menuChildren}) => {
    const {t} = useTranslation(['index']) as any;

    return (
        <DropdownLinkComponent tabIndex={0}>
            { t(label)}
            <DropdownLinkDropdown>
                {menuChildren.map((it) => (
                    it.link ?
                        <LinkRouter to={it.link} key={it.label}>{t(it.label)}</LinkRouter>
                        :
                        <LinkA href={it.href} key={it.label}>{t(it.label)}</LinkA>

                ))}
            </DropdownLinkDropdown>
        </DropdownLinkComponent>
    )
}

const LinksComponent = () => {
    const {t} = useTranslation(['index']) as any;

    return (
        <>
            {LinksList.map((it) => (
                it.link ?
                    <LinkRouter to={it.link} key={it.label}>{ t(it.label) }</LinkRouter>
                    : it.href ?
                        <LinkA href={it.href} key={it.label}>{ t(it.label) }</LinkA>
                    : <DropdownLink label={it.label} key={it.label} menuChildren={it.children || []}/>
            ))}
        </>
    )
}

export const HeaderLinks = withSuspense(LinksComponent, <Spinner size="xs" />)
