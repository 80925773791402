import styled, { css } from "styled-components";
import { DEFAULT_THEME } from "@legex/ui-kit";
import { FC } from "react";
import { OptionProps } from "react-select";


const OptionStyled = styled.div<{selected: boolean, focused: boolean}>`
    padding: 0.5rem 1rem;
    color: ${({selected}) => DEFAULT_THEME.color.neutral[selected ? 10 : 50]};
    cursor: pointer;
    outline: none;
    position: relative;
    
    background-color: ${({focused, selected}) => focused ? css`
        ${DEFAULT_THEME.color.primary[selected ? 60 : 20]};
    ` : css`
        ${selected ? DEFAULT_THEME.color.primary[50] : "transparent"}
    `}
`;

export const Option: FC<OptionProps<any, any>> = ({
    children,
    ...props
}) =>
    <OptionStyled focused={props.isFocused} selected={props.isSelected} {...props.innerProps}>{children}</OptionStyled>
