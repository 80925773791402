import { Social } from "./Social";
import { Envelope } from "@legex/icons";
import styled from "styled-components";
import { LINK_COLOR, LINK_COLOR_ACTIVE, LINK_COLOR_HOVER } from "../constants";
import { MOBILE_MAX_WIDTH } from "../../../constants";


const Email = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;
    font-size: 0.9rem;
    line-height: 0.9rem;
    color: ${LINK_COLOR};
    fill: ${LINK_COLOR};
    
    &:hover {
        color: ${LINK_COLOR_HOVER};
        fill: ${LINK_COLOR_HOVER};
    } 
    &:active {
        color: ${LINK_COLOR_ACTIVE};
        fill: ${LINK_COLOR_ACTIVE};
    }
    
    svg {
        height: 0.9rem;
        margin-right: 0.5rem;
    }
`;

const Wrapper = styled.div`
    flex: 0;
    display: grid;
    grid-template-rows: min-content min-content;
    grid-gap: 0.75rem;
    justify-items: stretch;
    
    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        justify-items: center;
    }
    
    a {
        color: currentColor;
        text-decoration: none;
    }
    
`;

const Name = styled.div`
    display: grid;
    color: ${LINK_COLOR};
    font-size: 0.8rem;
    white-space: nowrap;
    
    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        
        justify-items: center;
    } 
    
`;

export const Contacts = () => {
    return (
        <Wrapper>
            <Social />
            <a href="mailto:info@legex.io">
                <Email>
                    <Envelope weight="solid"/>
                    <span>info@legex.io</span>
                </Email>
            </a>
            <Name>
                &copy; 2021, Legex Europe OÜ (reg. 16246179)
            </Name>

        </Wrapper>
    )
}
