import { ComponentType, SuspenseProps, Suspense } from "react";

export function withSuspense<P extends string | number | object>(WrappedComponent: ComponentType<P>, fallback: SuspenseProps["fallback"] = null) {
    return (props: P) => {
        return (
            <Suspense fallback={fallback}>
                <WrappedComponent {...props} />
            </Suspense>
        )
    };
}
