import styled from "styled-components";
import { DEFAULT_THEME, Spinner } from "@legex/ui-kit";
import { Title } from "./components/Title";
import { HeaderLinks } from "./components/HeaderLinks";
import { UserInfo } from "./components/UserInfo";
import { SignButtons } from "./components/SignButtons";
import {MOBILE_MAX_WIDTH } from "../../constants";
import { LanguageSelector } from "./components/LanguageSelector";
import { MobileMenu } from "./components/MobileMenu";
import { UserMenu } from "./components/UserMenu";
import { FC, useState } from "react";
import { User, userService } from "../../utils/user";


const HEADER_GAP = 1

const LinksWrapper = styled.div`
    grid-area: links;
`;
const LanguageSelectorWrapper = styled.div`
    grid-area: language;
    color: ${DEFAULT_THEME.color.neutral[40]} !important;
`;

const UserAreaWrapper = styled.div`
    grid-area: user-area;
`;

const MobileMenuWrapper = styled.div`
    grid-area: menu-mobile;
    z-index: 1000;
`;

const UserMenuWrapper = styled.div`
    grid-area: user-menu;
`;

const TitleWrapper = styled.div`
    grid-area: title;
    
    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        justify-self: center;
    }

    @media(min-width: ${MOBILE_MAX_WIDTH}) {
        margin-right: 1.5rem;
    }
`;


export const HeaderContent = styled.div<{transparent: boolean}>`
    display: grid;
    grid-template-columns: min-content auto min-content min-content;
    grid-gap: ${HEADER_GAP}rem;
    align-items: center;
    padding: 0 2rem;
    z-index: 1;

    background: ${({transparent}) => transparent ? 'transparent' : DEFAULT_THEME.color.primary[70]};
    
    grid-template-areas: "title links user-area language";
    
    @media(max-width: ${MOBILE_MAX_WIDTH}) {
        grid-template-columns: min-content auto min-content;
        grid-template-areas: "menu-mobile title user-menu";
        
        ${LinksWrapper}, ${LanguageSelectorWrapper}, ${UserAreaWrapper} {
            display: none;
        }
    }

    @media(min-width: ${MOBILE_MAX_WIDTH}) {
        ${MobileMenuWrapper}, ${UserMenuWrapper} {
            display: none;
        }
    }
    
`;

export const Header: FC<{transparent: boolean}> = ( {transparent} ) => {

    const [user, setUser] = useState<User | null | undefined>(userService.user);

    userService.userEvents.addListener("user/update", (usr) => {
        setUser(usr)
    })

    const betaEnabled = localStorage.getItem("legex-beta")

    return (
        <HeaderContent transparent={transparent}>
                <TitleWrapper>
                    <Title />
                </TitleWrapper>
                <LinksWrapper>
                    <HeaderLinks />
                </LinksWrapper>
                { betaEnabled == 'true'
                    ?
                    <UserAreaWrapper>
                        { user === null ? <SignButtons /> : user === undefined ? <Spinner /> : <UserInfo user={user} /> }
                    </UserAreaWrapper>
                    :
                    null
                }
                <LanguageSelectorWrapper>
                    <LanguageSelector />
                </LanguageSelectorWrapper>
                <MobileMenuWrapper>
                    <MobileMenu />
                </MobileMenuWrapper>
                {/*<UserMenuWrapper>
                    <UserMenu />
                </UserMenuWrapper>*/}
        </HeaderContent>
    )
}
